import { USER_API } from "@/stores/apis"
import { SignInRequest, UserData } from "@infobex/corrigo-api"

import { ref, computed } from "vue"

const currentUser = ref<UserData | null>(null)
const pending = ref(true)

const isAdmin = computed<boolean>(() => {
	if (currentUser.value) {
		for (const role of currentUser.value.roles) {
			if (ADMIN_ROLES.includes(role)) return true
		}
	}
	return false
})

/**
 * TODO: refactor this piece of crap
 */
const ADMIN_ROLES = ["ADMIN", "ORG_ADM", "SALES_REP"]

async function revalidate() {
	try {
		pending.value = true

		const user = await USER_API.checkIdentity()
		currentUser.value = user
	} catch (err) {
		/**
		 * TODO: handle error
		 */
	} finally {
		pending.value = false
	}
}

async function signIn(params: SignInRequest) {
	const user = await USER_API.signIn({
		SignInRequest: params
	})
	currentUser.value = user
}

async function signOut() {
	await USER_API.signOut()
	currentUser.value = null
}

export function useAuthentication(strict?: boolean) {
	if (strict && !currentUser.value) {
		throw new Error("Component must be mounted within an authenticated user's context.")
	}

	return {
		currentUser,
		pending,
		revalidate,
		signOut,
		signIn,
		isAdmin
	}
}
