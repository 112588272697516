import { createApp } from "vue"
import { createPinia } from "pinia"
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import App from "./App.vue"
import router from "@/router"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import duration from "dayjs/plugin/duration"
import weekday from "dayjs/plugin/weekday"

import "dayjs/locale/hu"

import EmbedRoot from "@/embed/root.vue"
import embedRouter from "@/embed/router"

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(weekday)

dayjs.locale("hu-hu")

const is_self_contained = window.self === window.top

const force_iframe_mode = window.location.pathname.startsWith("/embed")

function mountAsSelfContained(selector: string) {
	const app = createApp(App)
	app.use(createPinia())
	app.use(autoAnimatePlugin)
	app.use(router)
	app.mount(selector)

	return app
}

function mountAsIframe(selector: string) {
	const app = createApp(EmbedRoot).use(autoAnimatePlugin).use(embedRouter)
	app.mount(selector)

	return app
}

if (is_self_contained && !force_iframe_mode) {
	const app = mountAsSelfContained("#app-root")

	//@ts-ignore
	window.corrigo = {
		force_iframe_mode: () => {
			app.unmount()

			mountAsIframe("#app-root")
		}
	}
} else {
	mountAsIframe("#app-root")
}
