<script setup lang="ts">
import { Bar } from "vue-chartjs"
import { RTXNET_API } from "@/stores/apis"
import { VLoader } from "@infobex/vue-ui-lib"
import { Icon, ModalDateSelector, Overlay } from "@/components/ui"
import AnnotationPlugin, { type AnnotationOptions } from "chartjs-plugin-annotation"
import dayjs, { Dayjs } from "dayjs"
import { ref } from "vue"
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js"

const source = ({ date }: { date: Dayjs }) => {
	return RTXNET_API.getResidualHistory({
		start_date: date.startOf("day").toDate() as unknown as string,
		end_date: date.endOf("day").toDate() as unknown as string
	})
}
ChartJS.register([
	Title,
	Tooltip,
	Legend,
	ArcElement,
	BarElement,
	CategoryScale,
	LinearScale,
	AnnotationPlugin
	// Title,
	// Tooltip,
	// PointElement,
	// LineElement,
	// Filler
])

type DateRange = {
	start_date: Dayjs
	end_date: Dayjs
}

const color_primary = "57 179 75"

const start_date = ref<Date>(new Date())
const end_date = ref<Date>(new Date())
start_date.value.setHours(0, 0, 0)
end_date.value.setHours(23, 59, 59)

const dateRange = ref<DateRange>({
	start_date: dayjs(),
	end_date: dayjs()
})

defineProps<{
	labels: string[]
	values: number[]
}>()

RTXNET_API.getI95HistoryAvailability().then(res => {
	dateRange.value.start_date = dayjs(res.start_date)
	dateRange.value.end_date = dayjs(res.end_date)
})
</script>

<template>
	<Bar
		:options="{
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				}
			},
			scales: {
				y: {
					title: {
						display: true,
						text: '95% bizonytalanság [m]'
					}
				},
				x: {
					title: {
						display: true,
						text: 'óra [helyi idő]'
					}
				}
			}
		}"
		:data="{
			labels: labels,
			datasets: [
				{
					/* yAxisID: 'main', */
					label: '%',
					backgroundColor: `rgba(${color_primary} / 75%)`,
					hoverBackgroundColor: `rgba(${color_primary} / 90%)`,
					data: values
				}
			]
		}"
	/>
</template>
