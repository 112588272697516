<!-- Chart.vue -->
<script setup lang="ts">
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	BarElement,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler
} from "chart.js"
import { Line } from "vue-chartjs"
import { CHART_CONFIG } from "./constants"

defineProps<{
	labels: string[]
	datasets: {
		key: string
		name: string
		color: string
	}[]
	data: Record<string, number[]>
}>()

ChartJS.register([Title, Tooltip, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler])
</script>

<template>
	<Line
		:options="CHART_CONFIG.options"
		:data="{
			labels: labels,
			datasets: datasets.map(dataset => ({
				...CHART_CONFIG.dataset,
				data: data[dataset.key],
				label: dataset.name,
				borderColor: `rgba(${dataset.color} / 75%)`,
				backgroundColor: `rgba(${dataset.color} / 75%)`,
				hoverBackgroundColor: `rgba(${dataset.color} / 100%)`
			}))
		}"
	/>
</template>
