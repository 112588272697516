import { CoreChartOptions, ScaleChartOptions, PluginChartOptions } from "chart.js"
import type { DeepPartial } from "node_modules/chart.js/dist/types/utils"

/**
 * This is only required because Chart.js typedefs are a garbage fire
 */
type ChartOpts = DeepPartial<CoreChartOptions<any> & ScaleChartOptions<any> & PluginChartOptions<any>>

// #region constants
export const DATE_FORMAT = "YYYY. MMM. D."

export const COLORS = {
	BLUE: "2 132 199",
	GREEN: "60 208 112",
	RED: "230 0 38",
	PURPLE: "153 50 204"
} as const

export const CHARTS = [
	{
		key: "ABS",
		title: "Abszolút",
		legend: false,
		datasets: [{ key: "abs", name: "Elmozdulás (mm)", color: COLORS.BLUE }]
	},
	{
		key: "REL",
		title: "Relatív",
		legend: false,
		datasets: [{ key: "rel", name: "Elmozdulás (mm)", color: COLORS.BLUE }]
	},
	{
		key: "XYZ",
		title: "XYZ",
		legend: true,
		datasets: [
			{ key: "x", name: "Keleti (mm)", color: COLORS.GREEN },
			{ key: "y", name: "Északi (mm)", color: COLORS.RED },
			{ key: "z", name: "Magassági (mm)", color: COLORS.PURPLE }
		]
	}
]

/**
 * Configuration object shared between all displayed charts.
 *
 * Alternate solution could be defining a self-contained chart component
 * with baked-in default options, and simplified props interface.
 */
export const CHART_CONFIG = {
	options: {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: "x"
		// scales: {
		// y: {
		// 	max: 40,
		// 	min: -40
		// }
		// }
		// plugins: {
		// legend: {
		// 	// display: true,
		// 	position: "right",
		// 	align: "start"
		// }
		// }
	} as ChartOpts,
	dataset: {
		label: "Elmozdulás",
		// fill: true,
		pointRadius: 3,
		pointHoverRadius: 5
	}
} as const
