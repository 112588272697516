export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/app',
    name: '/app',
    component: () => import('/app/src/pages/app.vue'),
    children: [
      {
        path: 'analytics',
        /* internal name: '/app/analytics' */
        /* no component */
        children: [
          {
            path: '',
            name: 'analytics-root',
            component: () => import('/app/src/pages/app/analytics/index.vue'),
            /* no children */
            meta: {
              "requiresAdminRights": true,
              "isDashboardItem": true,
              "displayName": "Statisztikák",
              "icon": "chart-simple",
              "index": 10000
            }
          },
          {
            path: 'subscriptions',
            name: 'analytics-subscriptions',
            component: () => import('/app/src/pages/app/analytics/subscriptions.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'igazolas',
        name: 'usage-reporting',
        component: () => import('/app/src/pages/app/igazolas.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "displayName": "Igazolások",
          "icon": "file-pdf",
          "index": 15000,
          "isDashboardItem": true
        }
      },
      {
        path: 'logins',
        /* internal name: '/app/logins' */
        /* no component */
        children: [
          {
            path: '',
            name: 'user-logins',
            component: () => import('/app/src/pages/app/logins/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "displayName": "Előfizetések",
              "icon": "key-skeleton-left-right",
              "index": 0,
              "isDashboardItem": true
            }
          },
          {
            path: 'new',
            name: 'user-place-order',
            component: () => import('/app/src/pages/app/logins/new.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'map',
        name: 'session-viewer',
        component: () => import('/app/src/pages/app/map.vue'),
        children: [
          {
            path: 'logins/:login_id',
            name: 'session-viewer-login',
            component: () => import('/app/src/pages/app/map/logins.[login_id].vue'),
            props: true,
            /* no children */
          }
        ],
        meta: {
          "requiresAuth": true,
          "displayName": "Térkép",
          "icon": "map-location-dot",
          "index": 250,
          "isDashboardItem": true,
          "hideOnMobile": true
        }
      },
      {
        path: 'orders',
        /* internal name: '/app/orders' */
        /* no component */
        children: [
          {
            path: '',
            name: 'ordermgmt',
            component: () => import('/app/src/pages/app/orders/index.vue'),
            /* no children */
            meta: {
              "requiresAdminRights": false,
              "isDashboardItem": true,
              "displayName": "Rendelések",
              "icon": "briefcase",
              "index": 12000
            }
          },
          {
            path: ':order_id',
            /* internal name: '/app/orders/[order_id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'ordermgmt-overview',
                component: () => import('/app/src/pages/app/orders/[order_id]/index.vue'),
                props: true,
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'profile',
        name: 'user-profile',
        component: () => import('/app/src/pages/app/profile.vue'),
        children: [
          {
            path: 'orders',
            name: 'user-profile-orders',
            component: () => import('/app/src/pages/app/profile/orders.vue'),
            props: true,
            /* no children */
          },
          {
            path: 'settings',
            name: 'user-profile-settings',
            component: () => import('/app/src/pages/app/profile/settings.vue'),
            props: true,
            /* no children */
          }
        ],
        meta: {
          "requiresAuth": true,
          "displayName": "Fiók",
          "icon": "gears",
          "index": 0,
          "isDashboardItem": true
        }
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () => import('/app/src/pages/app/subscriptions.vue'),
        /* no children */
        meta: {
          "requiresAdminRights": true,
          "requiresAuth": true,
          "displayName": "Lejáró előfizetések",
          "icon": "calendar-range",
          "index": 0,
          "isDashboardItem": true
        }
      },
      {
        path: 'user-management',
        /* internal name: '/app/user-management' */
        /* no component */
        children: [
          {
            path: '',
            name: 'user-management',
            component: () => import('/app/src/pages/app/user-management/index.vue'),
            /* no children */
            meta: {
              "requiresAdminRights": true,
              "isDashboardItem": true,
              "displayName": "Felhasználók",
              "icon": "users-gear",
              "index": 9000
            }
          },
          {
            path: ':user_id',
            name: 'admin-user-data',
            component: () => import('/app/src/pages/app/user-management/[user_id].vue'),
            props: true,
            children: [
              {
                path: 'forms/:schema_id?',
                name: 'usermgmt-account-metadata',
                component: () => import('/app/src/pages/app/user-management/[user_id]/forms.[[schema_id]].vue'),
                props: true,
                /* no children */
              },
              {
                path: 'logins',
                name: 'admin-user-logins',
                component: () => import('/app/src/pages/app/user-management/[user_id]/logins.vue'),
                props: true,
                children: [
                  {
                    path: ':login_id',
                    /* internal name: '/app/user-management/[user_id]/logins/[login_id]' */
                    /* no component */
                    children: [
                      {
                        path: 'forms/:schema_id?',
                        name: 'login-forms',
                        component: () => import('/app/src/pages/app/user-management/[user_id]/logins/[login_id]/forms.[[schema_id]].vue'),
                        props: true,
                        /* no children */
                      },
                      {
                        path: 'order',
                        name: 'login-place-order',
                        component: () => import('/app/src/pages/app/user-management/[user_id]/logins/[login_id]/order.vue'),
                        props: true,
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'orders',
                name: 'usermgmt-order-history',
                component: () => import('/app/src/pages/app/user-management/[user_id]/orders.vue'),
                props: true,
                /* no children */
              },
              {
                path: 'sessions',
                name: 'usermgmt-session-viewer',
                component: () => import('/app/src/pages/app/user-management/[user_id]/sessions.vue'),
                props: true,
                /* no children */
              },
              {
                path: 'settings',
                name: 'usermgmt-account-settings',
                component: () => import('/app/src/pages/app/user-management/[user_id]/settings.vue'),
                props: true,
                /* no children */
              },
              {
                path: 'subscriptions',
                name: 'admin-user-subscriptions',
                component: () => import('/app/src/pages/app/user-management/[user_id]/subscriptions.vue'),
                props: true,
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

