<script setup lang="ts">
import { UserData } from "@infobex/corrigo-api"
import { reactive } from "vue"

import { VTextInput } from "@infobex/vue-ui-lib/unstable"

import { VButton, VIcon } from "@infobex/vue-ui-lib/core"

import Wrapper from "./Wrapper.vue"
import { useAuthentication } from "@/composables/useAuthentication"

const emit = defineEmits<{
	(e: "success", v: UserData): void
	(e: "resetPassword"): void
}>()

const auth_params = reactive({
	username: "",
	password: ""
})

const { signIn } = useAuthentication()

async function handleSignIn() {
	await signIn(auth_params)
}
</script>
<!-- eslint-disable vue/v-on-handler-style -->
<template>
	<Wrapper @submit="handleSignIn">
		<template #title>Bejelentkezés</template>
		<template #content>
			<VTextInput
				v-model="auth_params.username"
				class="input-glass"
				name="username"
				type="text"
				placeholder="Felhasználónév / E-mail cím"
			>
				<template #start>
					<VIcon i="at" variant="light" />
				</template>
			</VTextInput>
			<VTextInput
				v-model="auth_params.password"
				class="input-glass"
				name="password"
				type="password"
				autocomplete="current-password"
				placeholder="Jelszó"
			>
				<template #start>
					<VIcon i="key" variant="light" />
				</template>
			</VTextInput>
			<VButton type="submit" variant="outlined" color="primary" :action="handleSignIn">
				Belépés
				<template #suffix>
					<VIcon i="circle-arrow-right" />
				</template>
			</VButton>
			<VButton variant="link" @click="() => emit('resetPassword')">Jelszó helyreállítása...</VButton>
		</template>
	</Wrapper>
</template>

<style lang="scss">
// .information {
// 	@apply flex flex-row;
// 	@apply items-center;
// 	@apply pl-4 pr-8 py-2  gap-4;
// 	@apply rounded;
// 	@apply text-sky-500 bg-sky-600 bg-opacity-20;
// 	@apply border-sky-500 border-opacity-20;
// }
</style>
