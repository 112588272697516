<script setup lang="ts">
/**
 * TODO:
 *  - Make this piece of crap responsive
 */
import dayjs, { Dayjs } from "dayjs"

import { VFormControl } from "@infobex/vue-ui-lib"
import { prettyPrintDateRange } from "@/utils/date"
import { VTextInput } from "@infobex/vue-ui-lib/unstable"
import { VIcon, useModal } from "@infobex/vue-ui-lib/core"

import Dialog from "./Dialog.vue"

const props = defineProps<{
	minDate: Dayjs
	maxDate: Dayjs
}>()

const modelValue = defineModel<[Dayjs, Dayjs]>({ default: () => [dayjs().subtract(1, "days"), dayjs()] })

async function openDialog() {
	const date = await useModal(Dialog, {
		minDate: dayjs().subtract(1, "year").endOf("year"),
		maxDate: dayjs(),
		defaultValue: modelValue.value
	})
	modelValue.value = date
}
</script>
<template>
	<VTextInput
		type="text"
		:model-value="prettyPrintDateRange(modelValue[0], modelValue[1]) ?? '--'"
		readonly
		@click="openDialog"
	>
		<template #start>
			<VIcon i="calendar" />
		</template>
	</VTextInput>
</template>
