<script setup lang="ts">
import { debounce } from "@/utils"
import { onMounted, reactive, ref } from "vue"
import { provideFormContext, useFormProvider, useFormRoot } from "./formContext"

export interface FormResolver {
	resolve: (message?: string) => void
	reject: (reason?: unknown) => void
}

const formRef = ref<HTMLFormElement>()

const props = withDefaults(
	defineProps<{
		formId?: string
		disabled?: boolean
		debounce?: number
		emitChangeEvents?: boolean
		disableValidationStyles?: boolean
		disallowResubmit?: boolean
		validateOnMount?: boolean
	}>(),
	{
		disallowResubmit: false,
		debounce: 600
	}
)

if (props.formId) {
	const data = reactive({})
	useFormRoot(data)
	useFormProvider(props.formId)
}

const emit = defineEmits<{
	(c: "submit", v: any, ctx: FormResolver): unknown
	(c: "changed", v: any, ctx?: FormResolver): unknown
}>()

const isDisabled = ref(false)

const message = ref<string>()
const error = ref<string>()

function emitWithContext(formData: unknown) {
	new Promise<string | void>((resolve, reject) => {
		isDisabled.value = true
		error.value = undefined
		message.value
		emit("submit", formData, { resolve, reject })
	})
		.then(msg => {
			message.value = msg as string | undefined
		})
		.catch(e => {
			error.value = e.toString?.()
		})
		.finally(() => {
			isDisabled.value = props.disallowResubmit
		})
}

const emitChangeEvent = debounce(() => {
	emit("changed", collectFormData())
}, props.debounce)

function collectFormData(e?: Event) {
	e?.preventDefault()
	if (!formRef.value) throw "No HTML node found"
	const formData = new FormData(formRef.value)
	return Object.fromEntries(formData)
}

function submit(e: Event) {
	const data = collectFormData(e)
	emitWithContext(data)
}

onMounted(() => {
	if (props.validateOnMount) {
		// console.log("PISS")
		formRef.value?.checkValidity()
		formRef.value?.reportValidity()
	}
})

provideFormContext({
	emitChangeEvent
})
</script>
<template>
	<form ref="formRef" :disabled="disabled || isDisabled" @submit="submit">
		<slot v-bind="{ disabled: disabled || isDisabled, error, message }" />
	</form>
</template>
<style lang="scss"></style>
