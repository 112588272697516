<!-- eslint-disable vue/require-default-prop -->
<script setup lang="ts">
import { Dayjs } from "dayjs"
import { VDateRange } from "@infobex/vue-ui-lib"
import { ref } from "vue"
import { VButton } from "@infobex/vue-ui-lib/core"
import GenericDialog from "@/components/dialogs/GenericDialog.vue"

const props = withDefaults(
	defineProps<{
		minDate?: Dayjs
		maxDate?: Dayjs
		defaultValue?: [Dayjs, Dayjs]
		title?: string
		color?: "primary" | "info"
	}>(),
	{
		color: "info"
	}
)

const emit = defineEmits<{
	(c: "resolve", v: [Dayjs, Dayjs] | null): void
	(c: "reject"): void
}>()

const innerValue = ref<[Dayjs, Dayjs]>(props.defaultValue)

function confirm() {
	emit("resolve", innerValue.value)
}

function cancel() {
	emit("resolve", null)
}
</script>
<template>
	<GenericDialog :title class="no-padding">
		<VDateRange
			v-model="innerValue"
			:min-date="props.minDate"
			:max-date="props.maxDate"
			color="info"
			class="overflow-auto sm:flex-col"
		/>
		<template #actions>
			<VButton size="lg" color="neutral" variant="outlined" @click="cancel">Bezár</VButton>
			<VButton size="lg" color="info" variant="filled" @click="confirm">OK</VButton>
		</template>
	</GenericDialog>
</template>
<style lang="scss"></style>
