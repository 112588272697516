// import { computed, ref } from "vue"

import { USER_API } from "@/stores/apis"
import { ref, watchEffect } from "vue"
import { useAuthentication } from "./useAuthentication"

const organizations = ref<{ organization_id: string; name: string }[]>([])

const selected = ref<string>()

const { currentUser } = useAuthentication()

async function update() {
	console.log("Updating memberships...")
	if (currentUser.value.organization_id) {
		selected.value = currentUser.value.organization_id
	}
	organizations.value = await USER_API.getOrganizationMemberships()
}

watchEffect(async () => {
	if (currentUser.value) {
		update()
	} else {
		organizations.value = []
	}
})

export function useSelectedOrganization() {
	return { organizations, selected, organization_id: selected }
}
