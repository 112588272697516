import { Ref, ref } from "vue"

type AsyncReturn<T extends (...args: any) => any> = Awaited<ReturnType<T>>

export function useQuery<T extends (...args: any) => any>(
	fn: T
): [
	(...args: Parameters<T>) => Promise<void>,
	{
		data: Ref<Awaited<ReturnType<T>> | null>
		error: Ref<any | null>
		pending: Ref<boolean>
	}
] {
	const pending = ref(false)
	const error = ref<any | null>(null)

	const data: Ref<Awaited<ReturnType<T>> | null> = ref(null)

	async function execute(...args: Parameters<T>) {
		pending.value = true
		try {
			// data.value = null
			const results: AsyncReturn<T> = await fn(...args)
			data.value = results

			return results
		} catch (err) {
			data.value = null
			error.value = err
		} finally {
			pending.value = false
		}
	}

	return [execute, { data, pending, error }]
}
