<script setup lang="ts">
import { ref } from "vue"

import { VButton, VIcon } from "@infobex/vue-ui-lib"
import { Overlay } from "@/components/ui"

defineProps<{
	title: string
}>()

const help_visible = ref(false)

function toggleHelp(_e: Event, v: boolean) {
	help_visible.value = v
}
</script>
<template>
	<div class="chart-container">
		<header class="chart-header">
			<span>{{ title }}</span>
			<VButton
				v-if="$slots.help"
				class="chart-help-btn"
				variant="filled"
				color="info"
				size="sm"
				:value="true"
				@click="toggleHelp"
			>
				<VIcon i="info" variant="regular" />
			</VButton>
		</header>

		<nav v-if="$slots.controls" class="chart-controls">
			<slot name="controls" />
		</nav>

		<nav class="chart-legend">
			<slot name="legend" />
		</nav>

		<main class="chart-container-main">
			<slot name="chart" />
		</main>

		<Overlay v-if="help_visible" class="chart-help-overlay">
			<div class="chart-help-content">
				<slot name="help" />

				<VButton color="info" variant="subdued" class="self-center" :value="false" @click="toggleHelp">
					<template #suffix>
						<VIcon i="check" />
					</template>
					OK
				</VButton>
			</div>
		</Overlay>
	</div>
</template>
<style lang="scss">
.chart-container {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	padding: 1rem;
}

.chart-container-main {
	flex-grow: 1;
	overflow: auto;
}

.chart-controls {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	padding: 1rem;
	gap: 1rem;
}

.chart-legend {
	display: flex;
	flex-direction: row;
	justify-content: center;

	gap: 1rem;
}

.chart-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	gap: 1rem;
	padding: 0.25rem;
	line-height: normal;
	font-size: 2rem;

	border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
}

.chart-help-btn {
	line-height: normal;
	padding: 4px !important;
}

.chart-help-overlay {
	display: flex;
	flex-direction: column;
	// padding: 2rem;
}

.chart-help-content {
	@apply shadow rounded;
	display: flex;
	flex-direction: column;
	text-align: justify;

	gap: 1rem;
	overflow: auto;
	background-color: white;
	padding: 1rem 2rem;
	max-width: 800px;
}
</style>
