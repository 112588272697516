<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs"
import { Card } from ".."
import { VDateSelect, VButton } from "@infobex/vue-ui-lib"
import Calendar from "@/components/ui/Calendar/Calendar.vue"
import { ref } from "vue"
import GenericDialog from "@/components/dialogs/GenericDialog.vue"

const props = withDefaults(
	defineProps<{
		title?: string
		minDate?: Dayjs
		maxDate?: Dayjs
		defaultValue?: Dayjs
		color?: "primary" | "info" // Hardcoded because I don't know where the types are right now
	}>(),
	{
		color: "info"
	}
)

const emit = defineEmits<{
	(c: "resolve", v: Dayjs | null): void
	(c: "reject"): void
}>()

const innerValue = ref(props.defaultValue ?? dayjs())

function cancel() {
	emit("resolve", null)
}

function confirm() {
	emit("resolve", innerValue.value)
}
</script>
<template>
	<GenericDialog v-bind="{ title }">
		<VDateSelect v-model="innerValue" name="date" required v-bind="{ minDate, maxDate }" :color />
		<template #actions>
			<VButton color="neutral" variant="outlined" @click="cancel">Mégsem</VButton>
			<VButton :color variant="filled" @click="confirm">OK</VButton>
		</template>
	</GenericDialog>
</template>
<style lang="scss"></style>
