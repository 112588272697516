<script setup lang="ts">
// import { RouterView } from "vue-router/auto"
import { NavBar } from "@/components/app"

// import { DashboardNavigation } from "./components/app/DashboardNavigation"

import Spinner from "./components/ui/Spinner.vue"

import { VModalOutlet } from "@infobex/vue-ui-lib/core"
import { VThemeProvider } from "@infobex/vue-ui-lib/draft"

import { AuthForm } from "./components/corrigo"

import { useConsoleCommands, useAuthentication } from "./composables"

import { onMounted } from "vue"

import "@/theme"
import ResetPassword from "./components/forms/ResetPassword.vue"

const { revalidate, pending, currentUser } = useAuthentication()

useConsoleCommands()

onMounted(() => {
	revalidate()
})
</script>
<template>
	<VThemeProvider
		:theme="{
			iconVariant: 'duotone'
		}"
	>
		<template v-if="pending">
			<Spinner />
		</template>
		<template v-else-if="currentUser">
			<NavBar :user="currentUser" />

			<ResetPassword v-if="currentUser.flags.require_password_reset" />

			<main v-else id="app-content">
				<RouterView />
			</main>
		</template>
		<template v-else>
			<AuthForm />
		</template>

		<VModalOutlet />
	</VThemeProvider>
</template>
<style src="./App.scss">
.v-dialog {
	&-enter-from,
	&-leave-to {
		position: absolute;
		opacity: 0;
		transition-property: opacity, transform;
		transition-duration: 375ms;
		transform: translate(0, -1rem) scale(0.9);
	}

	&-enter-to,
	&-leave-from {
		position: absolute;
		opacity: 1;
		transition-property: opacity, transform;
		transition-duration: 375ms;
		transform: translate(0, 0) scale(1);
	}
}
</style>
