<script setup lang="ts">
function preventDefault(e: Event) {
	e.preventDefault()
	emit("submit")
}

const emit = defineEmits<{
	(c: "submit"): void
}>()
</script>
<template>
	<form id="auth-form" @submit="preventDefault">
		<img src="/img/corrigo_white.png" />

		<main>
			<h6>
				<slot name="title" />
			</h6>
			<slot name="content" />
		</main>
	</form>
</template>
<style lang="scss">
#auth-form {
	@apply bg-black bg-opacity-50;

	@apply rounded;
	@apply shadow;
	@apply flex flex-col;

	// position: absolute;
	// top: 0;
	// right: 0;
	// bottom: 0;

	padding-block: 64px;
	padding-inline: 96px;

	overflow: hidden;
	margin: auto;
	// width: 560px;
	// backdrop-filter: blur(1px);

	// font-size: 2rem;

	@screen lg {
		width: 100%;
		height: 100%;
		align-items: center;
		// padding-inline: 98px;
		justify-content: center;
	}

	@screen sm {
		// font-size: 1.25rem;
		padding: 32px;
		align-items: stretch;
		justify-content: center;
		width: 100%;
	}

	width: 560px;
	// width: 480px;

	h6 {
		@apply text-2xl text-primary uppercase font-semibold;
		@apply text-center py-4;
	}

	img {
		height: 72px;
		margin-inline: auto;
	}

	main {
		@apply flex flex-col;
		// @apply items-stretch justify-center;
		@apply gap-8;
		// margin-inline: auto;
		// min-width: 360px;
	}

	footer {
		@apply flex flex-col;
		@apply justify-center items-stretch;
		@apply px-32 py-4 gap-4;
	}
}

input.input-glass {
	@apply text-white;
}

.v-input-container:has(> .input-glass) {
	@apply bg-neutral-900 bg-opacity-50;
	@apply border border-white border-opacity-25;
	@apply text-white;

	&:focus-within {
		@apply bg-black bg-opacity-30;
	}
}
</style>
