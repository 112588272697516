<script setup lang="ts">
import { VIcon, VButton } from "@infobex/vue-ui-lib/core"

defineProps<{
	title?: string
	message?: string | string[]
	html?: boolean
	icon?: string | null
	i?: string | null
	color?: "error" | "warning" | "info" | "success" | "neutral"
	hideActions?: boolean
	bodyClasses?: string //what?
}>()

const emit = defineEmits<{
	(c: "resolve", v: null): void
	(c: "reject", v?: unknown): void
}>()

function handleResolve() {
	emit("resolve", null)
}
</script>
<template>
	<div class="dialog-outer" :class="[color ?? 'neutral']">
		<!-- <slot name="icon"> -->
		<!-- </slot> -->
		<header v-if="title" class="dialog-header">
			<VIcon v-if="icon || i" size="24" variant="duotone" :i="i ?? icon" class="dialog-icon" />
			{{ title }}
		</header>
		<main v-if="!$slots.body" class="dialog-body" :class="bodyClasses">
			<slot>
				<template v-if="typeof message === 'string'">
					<p v-if="!html">
						{{ message }}
					</p>
					<!-- v-html is probably safe, since we're supposed to only passing it content hard-coded into the frontend -->
					<!-- eslint-disable-next-line vue/no-v-html -->
					<div v-if="html" v-html="message" />
				</template>
				<template v-if="Array.isArray(message)">
					<p v-for="(paragraph, idx) in message" :key="idx" class="dialog-paragraph">
						{{ paragraph }}
					</p>
				</template>
			</slot>
		</main>
		<slot name="body" />
		<footer v-if="!$slots.override" class="dialog-footer">
			<slot name="actions">
				<VButton color="neutral" variant="filled" class="px-16" @click="handleResolve">OK</VButton>
			</slot>
		</footer>
	</div>
</template>
<style lang="scss">
.dialog-outer {
	// @apply bg-slate-50;
	@apply text-slate-700;
	@apply rounded;
	@apply overflow-hidden;
	@apply flex flex-col;

	@apply transition-all;
	@apply shadow;

	@screen md {
		@apply rounded-none;
		height: 100dvh;
		width: 100dvw;
	}

	&.error {
		--accent-color: 220 38 38;
	}

	&.warning {
		--accent-color: var(--c-warning);
	}

	&.neutral {
		--accent-color: var(--c-neutral);
	}

	&.info {
		--accent-color: var(--c-info);
	}

	&.success {
		--accent-color: var(--c-primary);
	}

	.dialog-icon {
		// --blocksize: 48px;
		// color: rgba(var(--accent-color));
	}

	header.dialog-header {
		@apply flex items-center;
		@apply px-6 py-3 gap-3;
		@apply text-2xl;
		@apply text-white;
		@apply bg-zinc-700;
		// @apply bg-opacity-80;
		@apply shadow-lg;

		border-bottom: 6px solid rgba(var(--accent-color));
	}

	.dialog-body {
		@apply px-12;
		@apply py-8;
		@apply text-lg;
		@apply flex flex-grow;
		@apply overflow-auto;
		@apply bg-slate-50;

		@screen sm {
			@apply px-2;
		}
	}

	footer.dialog-footer {
		@apply bg-zinc-100;
		@apply border-t;
		@apply flex items-center justify-center;
		@apply px-6 py-2;
		@apply gap-8;
		@apply w-full;
	}
}

.no-padding .dialog-body {
	@apply p-0;
}

.dialog-paragraph:not(:last-child) {
	@apply mb-2;
}
</style>
