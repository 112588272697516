import { useMediaQuery } from "@vueuse/core"

function _helper(px: number) {
	return useMediaQuery(`(min-width: ${px}px)`)
}

export function useScreenSizes() {
	return {
		uhd: _helper(2560),
		fhd: _helper(1920),
		hd: _helper(1366),
		md: _helper(1024),
		sm: _helper(800),
		xs: _helper(412)
	}
}
