import { createRouter, createWebHistory } from "vue-router/auto"
import { routes } from "vue-router/auto-routes"

declare module "vue-router" {
	interface RouteMeta {
		hide?: boolean
		displayName?: string
		icon?: string
		rootId?: string
		index?: number
		isDashboardItem?: boolean
		isNavbarItem?: boolean
		isMenuRoot?: string
		isMenuItem?: string
		showFooter?: boolean
		showContact?: boolean
		requiresAuth?: boolean
		requiresRole?: string
		requiresRoles?: string[]
		requiresAdminRights?: boolean
		hideOnMobile?: boolean
		hideLoginPage?: boolean
	}
}

const router = createRouter({
	history: createWebHistory(),
	routes
})

/** Global auth guard */
router.beforeEach(async to => {
	// const { state } = useAppCore()
	// await state.ready
	// if (
	// 	// Check if the route is protected or not
	// 	to.meta.requiresAuth !== false &&
	// 	// make sure the user is authenticated
	// 	!state.currentUser &&
	// 	// ❗️ Avoid an infinite redirect
	// 	to.name !== "login-page"
	// ) {
	// 	console.log("Authguard caught navigation")
	// 	// redirect the user to the login page
	// 	return { name: "login-page", query: { from: to.path } }
	// }
})

/**	Admin guard */
// router.beforeEach(route => {
// 	if (route.meta.requiresAdminRights) {
// 		const { state } = useAppCore()
// 		if (!state.isAdmin && state.currentUser !== null) {
// 			console.log("not admin")
// 			// return {
// 			// 	name: "application-root",
// 			// 	replace: true
// 			// }
// 		}
// 		// 	await state.loaded
// 		// 		return {
// 		// 			name: "application-root",
// 		// 			replace: true
// 		// 		}
// 		// 	}
// 	}
// })

export default router
