<script setup lang="ts">
import OrganizationSelector from "../OrganizationSelector.vue"

import { VButton, VIcon } from "@infobex/vue-ui-lib/core"
import { UserData } from "@infobex/corrigo-api"

import { useAuthentication } from "@/composables/useAuthentication"
import { useScreenSizes } from "@/utils/responsive"

const { signOut } = useAuthentication()
const { xs, sm, md } = useScreenSizes()

defineProps<{
	user: UserData
}>()
</script>
<template>
	<nav id="navbar">
		<RouterLink :to="{ name: 'root' }">
			<div v-if="sm" class="brand" />
			<VIcon v-else i="home" size="24" variant="solid" />
		</RouterLink>

		<OrganizationSelector v-if="md" />

		<div class="navbar-userinfo">
			<span class="font-light text-lg flex items-center gap-1">
				<VIcon i="user" variant="solid" v-if="md" />
				<span v-if="md">Belépve:</span>
				<strong>{{ user.name }}</strong>
			</span>

			<VButton color="error" variant="filled" size="md" :action="signOut">
				<template v-if="xs" #default>Kilépés</template>
				<template v-if="xs" #suffix>
					<VIcon i="power-off" variant="solid" />
				</template>
				<VIcon v-if="!xs" i="person-to-door" size="20" variant="solid" />
			</VButton>
		</div>
	</nav>
</template>
<style lang="scss">
#navbar {
	@apply flex justify-between items-center;
	@apply bg-black;
	// padding-block: 14px;
	padding-inline: 18px;
	@apply text-white;
	// @apply backdrop-filter backdrop-blur;
	// @apply shadow-lg;
	// position: absolute;
	z-index: 100;
	top: 0px;
	// width: 100%;
	@apply overflow-y-visible overflow-x-clip;

	@apply py-4;
	@apply relative 2xl:sticky;

	@apply justify-between;
}

.brand {
	background-image: url("/img/corrigo_white.png");
	height: 36px;
	width: 200px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center left;
	@apply md:hidden;
}

.navbar-content {
	@apply flex items-center;
	// @apply gap-6;
	// @apply lg:hidden;

	@apply text-xl;
	@apply font-bold;
	// @apply lg:
}

.navbar-userinfo {
	@apply flex items-center gap-8;
	@apply md:justify-end md:w-full md:flex-grow;
	// @apply border-l border-white;
	// @apply pl-4;
}
</style>
