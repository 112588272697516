import { useSessionStorage } from "@vueuse/core"
import { LOCAL_STORAGE_KEYS as LS } from "@/utils/local_storage_keys"

const extended_admin_controls = useSessionStorage(LS.EXT_ADMIN_CTRLS, false)

function enableAdminControls(): null {
	extended_admin_controls.value = true
	console.log("extended admin controls enabled")
	return null
}

export function useConsoleCommands() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const wnd = window as any

	if (!wnd.enable_admin_controls) {
		wnd.enable_admin_controls = enableAdminControls
	}
}
