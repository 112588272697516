import { createRouter, createWebHistory } from "vue-router"

import { I95Chart, ResidualHistoryChart, StationDisplacementChart } from "@/components/charts"
// import TestFrame from "./frames/test-frame.vue"

export default createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/embed/i95-history",
			component: I95Chart
		},
		{
			path: "/embed/residual-history",
			component: ResidualHistoryChart
		},
		{
			path: "/embed/station/:station_code",
			component: StationDisplacementChart,
			props: true
		}
	]
})
