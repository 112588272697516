<script setup lang="ts">
import { ref } from "vue"
const inputRef = ref<HTMLInputElement>()

// const props =
defineProps<{
	modelValue?: string | number
	error?: boolean
	style?: any
	type?: string
}>()

const emit = defineEmits(["update:modelValue", "changed"])

const updateValue = (event: Event) => {
	const value = (event.target as HTMLInputElement)?.value
	emit("update:modelValue", value)
	emit("changed", value)
}
defineExpose({ inputRef })
</script>
<template>
	<span class="input" :class="{ error }" :style="style">
		<span v-if="$slots.prefix" class="input-adornment">
			<slot name="prefix" />
		</span>
		<component
			v-bind="$attrs"
			:is="type == 'select' ? 'select' : 'input'"
			ref="inputRef"
			class="input-content"
			:value="modelValue"
			:type="type != 'select' ? type : undefined"
			@input="updateValue"
		>
			<slot v-if="type === 'select'" name="options" />
		</component>
		<span v-if="$slots.suffix" class="input-adornment">
			<slot name="suffix" />
		</span>
		<span v-if="$slots.button" class="input-button">
			<slot name="button" />
		</span>
	</span>
</template>
<style lang="scss">
form.validated {
	.input {
		&:invalid {
			--bg-opacity: 20%;
			--color: var(--c-error);
		}
		// &:valid {}
	}
}

.input {
	@apply flex flex-row items-center;
	@apply box-border;
	@apply transition-all;
	// @apply border;
	@apply rounded-full;

	--border-opacity: 30%;
	--bg-opacity: 2%;
	--color: 82 82 82;

	border: 2px solid rgba(var(--color) / var(--border-opacity));
	background-color: rgba(var(--color) / var(--bg-opacity));

	overflow: hidden;

	padding-inline: 0.75rem;

	--padding: 0.5rem 0.5rem;

	&:hover {
		--border-opacity: 60%;
	}

	&:focus-within {
		--border-opacity: 80%;
		--bg-opacity: 5%;
		// --color: var(--c-info);
		box-shadow: 0px 0px 0px 2px rgba(var(--color) / 20%);
	}

	textarea {
		resize: none;
	}

	.input-content {
		@apply bg-transparent;
		position: relative;
		// @apply text-neutral-900;
		padding: var(--padding);
		flex-grow: 1;
		appearance: none;
		outline: none;
		width: var(--input-width);
		line-height: normal;

		&::placeholder,
		&.placeholder {
			@apply opacity-50;
		}
		// &.placeholder {
		// }

		&[disabled] {
			opacity: 0.5;
		}
	}

	.input-adornment {
		@apply text-opacity-80 text-inherit;
		@apply border-inherit;
		@apply flex items-center justify-center;
		// padding-inline: var(--padding);
	}

	&:focus-within .input-adornment {
		color: rgba(var(--color) / 100%);
	}
}

.input-wrapper {
	gap: 3px;

	.input {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.input + .button {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}

.input-wrapper {
	@apply flex items-stretch;
}
</style>
