<script setup lang="ts">
import { VButton, VIcon } from "@infobex/vue-ui-lib/core"
import Input from "../ui/Input.vue"
import { useSelectedOrganization } from "@/composables"

const { organizations, selected } = useSelectedOrganization()

function clear() {
	selected.value = undefined
}
</script>
<template>
	<Input
		v-if="organizations.length > 0"
		v-model="selected"
		type="select"
		class="text-black bg-white"
		placeholder="Szűrés szervezetre"
	>
		<template #options>
			<option v-for="org of organizations" :key="org.organization_id" :value="org.organization_id">
				{{ org.name }}
			</option>
		</template>
		<template #prefix>
			<VIcon i="sitemap" />
		</template>
		<template v-if="organizations.length > 1" #suffix>
			<VIcon v-if="selected !== undefined" class="cursor-pointer" i="xmark" @click="clear" />
		</template>
		<template v-else #suffix>
			<VIcon i="lock-keyhole" />
		</template>
	</Input>
</template>
<style lang="scss"></style>
