import { toRef, ref, shallowReactive, inject, provide, reactive } from "vue"

const FormCtx = Symbol("FormContext")

const FORM_CONTEXT = Symbol("form-context")

interface FormContext {
	emitChangeEvent: () => any
}

/** @deprecated */
export function provideFormContext(ctx: FormContext) {
	provide(FORM_CONTEXT, ctx)
}

/** @deprecated */
export function useFormContext() {
	const ctx = inject<FormContext>(FORM_CONTEXT)
	// if (!ctx) throw "No formstate to inject"
	return ctx
}

/** @deprecated */
export function useFormRoot(root: any) {
	const data = reactive(root)
	provide(FormCtx, data)
}

/** @deprecated */
export function useFormProvider(key?: string) {
	if (key === "0") return provide(FormCtx, null)

	const data = inject(FormCtx) as any

	if (!data) throw "No form root object"

	if (key) {
		if (!data[key]) {
			const subTree = {}
			data[key] = subTree
		}

		provide(FormCtx, data[key])

		return data[key]
	} else {
		return data
	}
}

/** @deprecated */
export function useFormData(field?: string) {
	if (!field) return null

	const data = inject(FormCtx)

	if (!data) return null
	if (!data[field]) data[field] = ""

	return data
}
