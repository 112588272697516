import {
	AnalyticsApi,
	RtxNetApi,
	Configuration as ApiConfiguration,
	NetworkApi,
	UserApi,
	SubscriptionsApi,
	SessionsApi,
	LoginsApi,
	OrdersApi,
	StatisticsApi,
	ConfigApi,
	RuntimeException,
	FormsApi,
	UserConfigApi
} from "@infobex/corrigo-api"

const locale = navigator.language.split("-")[0]

export class CorrigoRuntimeException {
	code: string
	message?: string
	constructor(ex: RuntimeException) {
		if (ex.message) this.message = ex.message[locale] ?? ex.message.en
		this.code = ex.code
	}
}

const apiConfig = new ApiConfiguration({
	basePath: "/api/v1",

	fetchApi: async (url: string, init: RequestInit) => {
		const response = await fetch(url, { ...init, credentials: "include" })

		if (response.status < 200 || response.status > 300) {
			const body = await response.json()

			if (body.class === "RuntimeException") {
				throw new CorrigoRuntimeException(body as RuntimeException)
			}
		}

		return response
	}
})

export const USER_API = new UserApi(apiConfig)

export const USER_CONFIG_API = new UserConfigApi(apiConfig)

/**
 * @deprecated
 */
export const networkApi = new NetworkApi(apiConfig)

export const SUBSCRIPTIONS_API = new SubscriptionsApi(apiConfig)

export const ORDERS_API = new OrdersApi(apiConfig)

export const SESSIONS_API = new SessionsApi(apiConfig)

export const LOGINS_API = new LoginsApi(apiConfig)

/**
 * @deprecated
 */
export const statisticsApi = new StatisticsApi(apiConfig)

export const configApi = new ConfigApi(apiConfig)

export const RTXNET_API = new RtxNetApi(apiConfig)

export const FORMS_API = new FormsApi(apiConfig)

export const ANALYTICS_API = new AnalyticsApi(apiConfig)
