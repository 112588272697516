<script setup lang="ts">
import { VFormControl, VIcon } from "@infobex/vue-ui-lib"
import { useModal } from "@infobex/vue-ui-lib/core"

import dayjs, { Dayjs } from "dayjs"
import Dialog from "./Dialog.vue"
import { VTextInput } from "@infobex/vue-ui-lib/unstable"

const modelValue = defineModel<Dayjs>()

const props = defineProps<{
	minDate?: Dayjs
	maxDate?: Dayjs
	title?: string
}>()

let isOpen = false

async function openDialog() {
	if (isOpen) return
	isOpen = true

	const date = await useModal(Dialog, {
		minDate: props.minDate,
		maxDate: props.maxDate,
		defaultValue: modelValue.value,
		title: props.title
	})

	if (date) {
		modelValue.value = date
	}

	isOpen = false
}

const formatString = "YYYY. MMM. D."
</script>
<template>
	<div @click="openDialog">
		<VTextInput type="text" :model-value="modelValue?.format(formatString) ?? '-'" name="date">
			<template #start>
				<VIcon i="calendar" />
			</template>
		</VTextInput>
	</div>
</template>
<style lang="scss"></style>
