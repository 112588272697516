<script setup lang="ts">
import { Form, FieldSet, Overlay, Spinner } from "@/components/ui"
import { VIcon, VButton } from "@infobex/vue-ui-lib/core"

import { USER_API } from "@/stores/apis"

import { sleep } from "@/utils/timers"
import { computed, reactive } from "vue"
import type { FormResolver } from "../ui/Form/Form.vue"
import { useAuthentication } from "@/composables"
const auth = reactive({
	password: "",
	confirm_password: ""
})

async function handleSubmit(e, ctx: FormResolver) {
	if (auth.password != auth.confirm_password) {
		ctx.reject("A megadott jelszavak nem egyeznek")
	}
	try {
		await sleep(1200)
		await USER_API.setPassword({
			SetPasswordRequest: {
				new_password: auth.password
			}
		})
		await useAuthentication().revalidate()
		await ctx.resolve()
	} catch (e: any) {
		if (e.response) ctx.reject(e.response)
	} finally {
		ctx.resolve()
	}
	// currentUser!.require_password_reset = false
}

const isValid = computed(() => {
	return auth.password && auth.password == auth.confirm_password
})

const { currentUser } = useAuthentication()
</script>
<template>
	<Form id="set-password-form" v-slot="{ disabled }" class="dark" @submit="handleSubmit">
		<h5 class="flex flex-col items-center text-center text-sky-600 text-2xl gap-2">
			<VIcon i="shield-exclamation" size="64" />
			<span>Új jelszó beállítása szükséges</span>
		</h5>
		<hr class="opacity-25" />
		<input hidden name="username" :value="currentUser?.username" />

		<span class="text-xl flex gap-2 items-center">
			<span>Felhasználónév:</span>
			<strong class="text-xl">{{ currentUser?.username }}</strong>
		</span>
		<FieldSet
			v-model="auth.password"
			required
			placeholder="Jelszó"
			type="password"
			autocomplete="new-password"
			name="new_password"
			minlength="10"
		>
			<template #prefix>
				<VIcon i="lock" />
			</template>
		</FieldSet>
		<FieldSet
			v-model="auth.confirm_password"
			required
			placeholder="Jelszó megerősítése"
			type="password"
			autocomplete="new-password"
		>
			<template #prefix>
				<VIcon i="lock" />
			</template>
		</FieldSet>
		<VButton color="info" variant="filled" size="lg" v-bind="{ disabled: disabled || !isValid }" type="submit">
			Jelszó beállítása
			<template #suffix>
				<VIcon i="check-double" size="24" />
			</template>
		</VButton>
		<Overlay v-if="disabled" dark>
			<Spinner size="128" thickness="16" color="info">
				<!-- <VIcon size="32" i="lock" class="text-sky-600 fa-beat-fade" /> -->
			</Spinner>
		</Overlay>
	</Form>
</template>
<style lang="scss">
#set-password-form {
	@apply flex flex-col gap-5;
	@apply m-auto px-20 py-12;
	@apply relative;
	width: 35rem;
	@apply shadow-lg;
	@apply rounded;

	.input {
		@apply text-xl;
	}
}

form.dark {
	@apply text-white bg-black bg-opacity-80;

	.input {
		@apply bg-neutral-900;
		@apply border-neutral-800;
	}
}
</style>
