<script setup lang="ts">
import { UserData } from "@infobex/corrigo-api"
import { ref } from "vue"

import { VTextInput } from "@infobex/vue-ui-lib/unstable"

import { VButton, VIcon } from "@infobex/vue-ui-lib/core"
import { useQuery } from "@/composables"
import { USER_API } from "@/stores/apis"
import Wrapper from "./Wrapper.vue"

const emit = defineEmits<{
	(e: "success", v: UserData): void
	(e: "back"): void
}>()

const email = ref("")

const [handleSubmit, { data }] = useQuery(async () => {
	return await USER_API.requestPasswordReset({
		RequestPasswordResetRequest: {
			email: email.value
		}
	})
})
</script>
<!-- eslint-disable vue/v-on-handler-style -->
<template>
	<Wrapper @submit="handleSubmit">
		<template #title>FIÓK HELYREÁLLÍTÁSA</template>
		<template #content>
			<div v-autoanimate>
				<p v-if="!data" key="1" class="text-white">
					Ügyfélfiókja jelszavának helyreállítása érdekében, kérjük, adja meg regisztrált e‑mail címét, és kövesse
					az e‑mailben kapott utasításokat.
				</p>
				<p v-if="data" key="2" class="text-sky-400">
					A megadott e-mail címre rendszerünk küldött Önnek egy levelet. Az abban található link visszairányítja
					oldalunkra, ahol beállíthatja jelszavát.
				</p>
			</div>

			<VTextInput v-model="email" class="input-glass">
				<template #start>
					<VIcon i="at" variant="regular" />
				</template>
			</VTextInput>

			<VButton
				type="button"
				variant="outlined"
				color="info"
				:action="handleSubmit"
				:disabled="data !== null && data !== undefined"
			>
				E-mail küldése
				<template #suffix>
					<VIcon i="send" />
				</template>
			</VButton>
			<VButton color="info" variant="link" @click="() => emit('back')">Vissza a bejelentkező oldalra</VButton>
		</template>
	</Wrapper>
</template>
<style lang="scss"></style>
