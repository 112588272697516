<script setup lang="ts">
import { debounce } from "@/utils"
import { computed, onMounted, reactive, ref, toRef, watch } from "vue"
import Input from "./Input.vue"
import { VIcon } from "@infobex/vue-ui-lib/core"
import { useFormContext, useFormData, useFormProvider } from "./Form"

export interface FieldsetProps {
	modelValue?: string | number
	defaultValue?: string | number
	label?: string
	helpText?: string
	validator?: (v: any) => Promise<string | undefined>
	debounceValidation?: number
	required?: boolean
	placeholder?: string
	type?: string
	autocomplete?: string
	pattern?: string
	name?: string
	minlength?: number | string
	maxlength?: number | string
	min?: number | string
	max?: number | string
	icon?: string
	validationIcons?: boolean
	readonly?: boolean
	disableValidation?: boolean
}

const props = withDefaults(defineProps<FieldsetProps>(), {
	validationIcons: true
})

const emit = defineEmits(["update:modelValue", "changed"])

const formContext = useFormContext()

const __injected = useFormData(props.name)
const __injectedValue = props.name ? __injected?.[props.name] : null
// console.log(__injected)
const InputRef = ref<InstanceType<typeof Input>>()
const inputElement = computed(() => {
	return InputRef.value?.inputRef ?? null
})

const asyncValidator = props.validator ? debounce(props.validator, props.debounceValidation ?? 1000) : null

const state = reactive({
	validity: null as null | ValidityState,
	validationMessage: "",
	validated: false as "pending" | boolean
})

function handleChange(value: string) {
	if (props.modelValue !== undefined) emit("update:modelValue", value)
	if (props.name && __injected) {
		__injected[props.name] = value
	}
	const el = inputElement.value!
	emit("changed", value)
	emit("update:modelValue", value)
	formContext?.emitChangeEvent()
	if (props.validator) {
		handleAsyncValidation(value)
	} else {
		if (!props.disableValidation) displayValidationStatus()
	}
}

async function handleAsyncValidation(v: any) {
	const el = inputElement.value!
	if (v) {
		state.validated = "pending"
		const msg = await asyncValidator?.(v)
		el.setCustomValidity(msg ?? "")
		el.reportValidity()
		displayValidationStatus()
		state.validated = true
	}
}

function displayValidationStatus(e?: Event) {
	/* Read out HTML validation status*/
	e?.preventDefault()
	const { validity, validationMessage } = inputElement.value!
	state.validated = true
	state.validity = validity
	state.validationMessage = validationMessage
}
// const asyncValidation = debounce(props.validator, props.debounceValidation)
onMounted(() => {
	inputElement.value!.addEventListener("invalid", displayValidationStatus)
})

const innerValue = ref(props.modelValue ?? __injectedValue ?? props.defaultValue ?? "")

if (__injectedValue && props.modelValue) {
	emit("update:modelValue", __injectedValue)
}

watch(
	() => props.modelValue,
	() => {
		innerValue.value = props.modelValue
	}
)
</script>
<template>
	<fieldset ref="fieldsetRef" v-autoanimate class="fieldset" :class="{ validated: state.validated === true }">
		<label>
			<slot name="label">
				<span v-if="label" class="fieldset-label">{{ label }}:</span>
			</slot>
			<slot>
				<Input
					ref="InputRef"
					v-model="innerValue"
					v-bind="{
						required,
						type,
						autocomplete,
						defaultValue,
						pattern,
						placeholder,
						name,
						minlength,
						maxlength,
						min,
						max,
						readonly
					}"
					@changed="handleChange"
				>
					<template v-if="icon || $slots.icon || $slots.prefix" #prefix>
						<slot name="icon">
							<VIcon v-if="icon" :i="icon" size="20" />
						</slot>
						<slot name="prefix" />
					</template>
					<template v-if="validationIcons || $slots.suffix" #suffix>
						<slot name="suffix" />
						<template v-if="validationIcons">
							<VIcon v-if="state.validated === 'pending'" class="fa-spin text-sky-600" i="spinner" size="20" />
							<VIcon v-else-if="state.validity?.valid === false" i="circle-x" class="text-red-600" size="20" />
							<VIcon
								v-else-if="state.validity?.valid === true"
								i="circle-check"
								class="text-green-600"
								size="20"
							/>
							<VIcon v-else-if="state.validity === null" i="spinner" size="20" class="opacity-0" />
						</template>
					</template>
				</Input>
			</slot>
		</label>
		<slot name="helptext" :message="state.validationMessage" :help-text="helpText" :value="innerValue">
			<p v-if="helpText || state.validationMessage" class="fieldset-helptext">
				{{ helpText }}
				<br v-if="helpText && state.validationMessage" />
				{{ state.validationMessage }}
			</p>
		</slot>
	</fieldset>
</template>
<style lang="scss">
form:focus-within {
	fieldset.fieldset.validated {
		&:invalid .input {
			--color: var(--c-error);
			--border-opacity: 60%;
			&:not(:focus-within) {
				--bg-opacity: 20%;
			}
		}

		&:invalid .fieldset-helptext {
			// @apply text-red-600;
		}

		&:valid .input {
			--color: var(--c-primary);
			--border-opacity: 60%;
			&:not(:focus-within) {
				--bg-opacity: 20%;
			}
		}
	}
}
fieldset.fieldset {
	&.horizontal label {
		@apply flex-row gap-2 items-center;
	}

	label {
		@apply flex flex-col;
		span.fieldset-label {
			@apply text-xl sm:text-sm;
			@apply ml-3;
			font-weight: 500;
		}
	}

	p.fieldset-helptext {
		@apply pl-4 mt-0.5;
		@apply text-sm;
		@apply text-neutral-600;
		line-height: normal;
	}
}
</style>
