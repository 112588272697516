<script setup lang="ts">
import { ref } from "vue"
import { SignInForm } from "."
import ResetPasswordForm from "./ResetPasswordForm.vue"

const view = ref<"sign-in" | "reset">("sign-in")
</script>
<!-- eslint-disable vue/v-on-handler-style -->
<template>
	<template v-if="view == 'sign-in'">
		<SignInForm
			@reset-password="
				() => {
					view = 'reset'
				}
			"
		/>
	</template>
	<template v-if="view == 'reset'">
		<ResetPasswordForm
			@back="
				() => {
					view = 'sign-in'
				}
			"
		/>
	</template>
</template>
<style lang="scss"></style>
