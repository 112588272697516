<script setup lang="ts">
import { computed } from "vue"
import AnnotationPlugin, { type AnnotationOptions } from "chartjs-plugin-annotation"
import { Bar } from "vue-chartjs"
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from "chart.js"

const color_stops = [
	{
		name: "good",
		color: "#22c55e",
		at: 0.2
	},
	{
		name: "warning",
		color: "#eab308",
		at: 0.4
	},
	{
		name: "critical",
		color: "#dc2626",
		at: 0.8
	}
]

defineProps<{
	labels: string[]
	values: number[]
}>()

const bounds = [0, 10]

const threshold_annotation_width = 2

ChartJS.register([
	Title,
	Tooltip,
	Legend,
	ArcElement,
	BarElement,
	CategoryScale,
	LinearScale,
	AnnotationPlugin
	// Title,
	// Tooltip,
	// PointElement,
	// LineElement,
	// Filler
])

const annotations = computed(() => {
	return color_stops.reduce((acc, cur) => {
		acc[cur.name] = {
			type: "line",
			yMin: bounds[1] * cur.at,
			yMax: bounds[1] * cur.at,
			borderColor: cur.color,
			borderWidth: threshold_annotation_width
		}
		return acc
	}, {} as AnnotationOptions<any>)
})

let width, height, gradient

function getGradient(ctx, chartArea) {
	const chartWidth = chartArea.right - chartArea.left
	const chartHeight = chartArea.bottom - chartArea.top
	if (!gradient || width !== chartWidth || height !== chartHeight) {
		// Create the gradient because this is either the first render
		// or the size of the chart has changed
		width = chartWidth
		height = chartHeight

		gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)

		color_stops.forEach((stop, i) => {
			// if (color_stops[i - 1]) {
			// 	gradient.addColorStop(stop.at - fade_offset, color_stops[i - 1].color)
			// }

			gradient.addColorStop(stop.at, stop.color)
		})
	}

	return gradient
}
</script>
<template>
	<Bar
		:options="{
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				annotation: { annotations }
			},
			scales: {
				y: {
					max: bounds[1],
					min: bounds[0],
					title: {
						display: true,
						text: 'i95 Index'
					}
				},
				x: {
					title: {
						text: 'óra [helyi idő]',
						display: true
					}
				}
			}
		}"
		:data="{
			labels: labels,
			datasets: [
				{
					label: 'Index',
					data: values,
					backgroundColor: function (context) {
						const chart = context.chart
						const { ctx, chartArea } = chart

						if (!chartArea) {
							return
						}

						return getGradient(ctx, chartArea)
					}
				}
			]
		}"
	/>
</template>
